import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import * as i0 from "@angular/core";
var NativeGeocoder = /** @class */function (_super) {
  __extends(NativeGeocoder, _super);
  function NativeGeocoder() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  NativeGeocoder.prototype.reverseGeocode = function (latitude, longitude, options) {
    return cordova(this, "reverseGeocode", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  NativeGeocoder.prototype.forwardGeocode = function (addressString, options) {
    return cordova(this, "forwardGeocode", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  NativeGeocoder.ɵfac = /* @__PURE__ */(() => {
    let ɵNativeGeocoder_BaseFactory;
    return function NativeGeocoder_Factory(__ngFactoryType__) {
      return (ɵNativeGeocoder_BaseFactory || (ɵNativeGeocoder_BaseFactory = i0.ɵɵgetInheritedFactory(NativeGeocoder)))(__ngFactoryType__ || NativeGeocoder);
    };
  })();
  NativeGeocoder.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: NativeGeocoder,
    factory: NativeGeocoder.ɵfac
  });
  NativeGeocoder.pluginName = "NativeGeocoder";
  NativeGeocoder.plugin = "cordova-plugin-nativegeocoder";
  NativeGeocoder.pluginRef = "nativegeocoder";
  NativeGeocoder.repo = "https://github.com/sebastianbaar/cordova-plugin-nativegeocoder";
  NativeGeocoder.platforms = ["iOS", "Android"];
  NativeGeocoder = __decorate([], NativeGeocoder);
  return NativeGeocoder;
}(AwesomeCordovaNativePlugin);
export { NativeGeocoder };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NativeGeocoder, [{
    type: Injectable
  }], null, {
    reverseGeocode: [],
    forwardGeocode: []
  });
})();
